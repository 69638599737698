import React from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import useHasMounted from "../../utils/useHasMounted"
import Header from "../Header/Header"
import "./About.css"
import AboutCopy from "./AboutCopy"
import FAQs from "./FAQs.js"
import Glossary from "./Glossary"

const About = ({ abouts, faqs, terms, team }) => {
  const hasMounted = useHasMounted()
  if (!hasMounted) return null
  const params = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  )

  const updateParams = (param, faqNumber) => {
    params.set(param, faqNumber)
    window.history.replaceState({}, "", `${window.location.pathname}?${params}`)
  }

  return (
    <section className="about">
      <Header title="About the Project" hideHelp />
      <Tabs
        defaultIndex={params.get("tab") || 0}
        onSelect={(idx) => {
          updateParams("tab", idx)
        }}
      >
        <TabList>
          <Tab tabIndex="0">
            <span>About</span>
          </Tab>
          <Tab tabIndex="0">
            <span>FAQ</span>
          </Tab>
          <Tab tabIndex="0">
            <span>Glossary</span>
          </Tab>
        </TabList>

        <div className="copy-wrap">
          <TabPanel>
            <h3>About</h3>
            <AboutCopy
              abouts={abouts}
              params={params}
              updateParams={updateParams}
              team={team}
            />
          </TabPanel>
          <TabPanel>
            <h3>Frequently Asked Questions</h3>
            <FAQs faqs={faqs} params={params} updateParams={updateParams} />
          </TabPanel>
          <TabPanel>
            <h3>Glossary</h3>
            <Glossary
              terms={terms}
              params={params}
              updateParams={updateParams}
            />
          </TabPanel>
        </div>
      </Tabs>
    </section>
  )
}
export default About
