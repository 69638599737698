import React, { useState } from "react"
import "react-tabs/style/react-tabs.css"
import angoleInterview from "../../images/angoleInterview.jpg"
import angolite from "../../images/angolite.jpg"
import loyola from "../../images/loyola.png"
import stateFarm from "../../images/stateFarm.png"
import vitalprojectsfund from "../../images/vitalprojectsfund.png"
import { handleKeyUp } from "../../utils"
import "./AboutCopy.css"

const OneAbout = ({ about, idx, aboutNumber, handleClickAbout }) => {
  const title = about ? about.node.data.about_this_project.text : ""
  const content = about ? about.node.data.about_content : []
  const isProject = title === "About the Visiting Room Project"
  const isAngola = title === "About Angola"
  const isAngolite = title === "About The Angolite"

  return (
    <div
      className={`about ${idx === aboutNumber ? "about-active" : ""}`}
      id={`about-${idx}`}
      onClick={(e) => {
        handleClickAbout(idx, e)
      }}
      onKeyUp={(ev) =>
        handleKeyUp(ev, () => {
          handleClickAbout(idx, ev)
        })
      }
      role="button"
      tabIndex="0"
      aria-label={`See ${title}`}
    >
      <h4>{title}</h4>
      <div
        className="about-content"
        style={{
          maxHeight: idx === aboutNumber && idx === 4 && "10000px",
        }}
      >
        <img
          src={
            isProject
              ? angoleInterview
              : isAngolite
              ? angolite
              : isAngola
              ? stateFarm
              : null
          }
          style={{ width: "100%", height: "auto" }}
          alt={
            isProject
              ? "An image of the setting where the interviews were conducted in Louisiana State Penitentiary."
              : isAngolite
              ? "An image of the cover of the magazine The Angolite, from 1991."
              : isAngola
              ? "An image of Angola as a state farm in the year 1900"
              : ""
          }
        />
        <div dangerouslySetInnerHTML={{ __html: content.html }}></div>
        <div className="map">
          <p
            className="caption"
            style={{
              textAlign: "left",
              marginBottom: "20px",
              marginTop: "10px",
              fontSize: "var(--font-small)",
            }}
          >
            {isAngola
              ? "LSU Libraries Special Collections / Andrew D. Lytle's Baton Rouge Photograph Collection (1900-1901, questionable)"
              : ""}
          </p>
        </div>
      </div>
    </div>
  )
}

const AboutCopy = ({ abouts, params, team, updateParams }) => {
  const [aboutNumber, setAboutNumber] = useState(+params.get("about") || null)

  params.delete("term")
  params.delete("faq")

  const parentAbout = abouts.find(
    (about) =>
      about.node.data.about_this_project.text ===
      "About the Visiting Room Project"
  )
  const childrenAbouts = abouts.filter(
    (about) =>
      about.node.data.about_this_project.text !==
      "About the Visiting Room Project"
  )

  const sortedAbouts = [
    childrenAbouts.find(
      (a) => a.node.data.about_this_project.text === "About Life Without Parole"
    ),
    childrenAbouts.find(
      (a) => a.node.data.about_this_project.text === "About Angola"
    ),
    childrenAbouts.find(
      (a) => a.node.data.about_this_project.text === "About The Angolite"
    ),
    childrenAbouts.find(
      (a) =>
        a.node.data.about_this_project.text ===
        "The Visiting Room Project return to Angola"
    ),
  ]

  const handleClickAbout = (idx, e) => {
    if (e?.target?.tagName === "A") return
    updateParams("about", idx === aboutNumber ? -1 : idx)
    setAboutNumber(idx === aboutNumber ? -1 : idx)
    setTimeout(
      document.querySelector(`#about-${idx}`).scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      }),
      1200
    )
  }

  return (
    <>
      <article className="copy">
        <OneAbout
          about={parentAbout}
          idx={0}
          aboutNumber={aboutNumber}
          handleClickAbout={handleClickAbout}
        />

        {sortedAbouts.map((about, idx) => {
          return (
            <OneAbout
              about={about}
              idx={idx + 1}
              key={`one-about-${idx + 1}`}
              aboutNumber={aboutNumber}
              handleClickAbout={handleClickAbout}
            />
          )
        })}
        <div
          className={`about ${
            sortedAbouts.length + 1 === aboutNumber ? "about-active" : ""
          }`}
          id={`about-${sortedAbouts.length + 1}`}
          onClick={(e) => {
            handleClickAbout(sortedAbouts.length + 1, e)
          }}
          onKeyUp={(ev) =>
            handleKeyUp(ev, () => {
              handleClickAbout(sortedAbouts.length + 1, ev)
            })
          }
          role="button"
          tabIndex="0"
          aria-label={`See About Team`}
        >
          <h4>{team.team_title.text}</h4>
          <div className="about-content">
            <p>{team.team_text.text}</p>
            <div
              dangerouslySetInnerHTML={{ __html: team.team_text.html }}
            ></div>
            <div className="logos">
              <img
                src={loyola}
                style={{ width: "64px", height: "auto" }}
                alt={"Loyola University Logo"}
              />
              <img
                src={vitalprojectsfund}
                style={{ width: "auto", height: "20px" }}
                alt={"Vital Projects Fund Logo"}
              />
            </div>
          </div>
        </div>
      </article>
    </>
  )
}

export default AboutCopy
