import React, { useEffect, useState } from "react"
import { handleKeyUp } from "../../utils"
import "./FAQs.css"

const FAQs = ({ faqs, params, updateParams }) => {
  const [faqNumber, setFaqNumber] = useState(params.get("faq") || 0)

  params.delete("term")
  params.delete("about")

  useEffect(() => {
    if (params.get("faq") && +params.get("faq") > -1) {
      setTimeout(
        document.querySelector(`#faq-${params.get("faq")}`)?.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        }),
        400
      )
    }
  }, [params])

  const faqParam = params.get("faq")

  useEffect(() => {
    if (
      faqNumber === -1 ||
      +params.get("faq") === -1 ||
      params.get("faq") === null
    ) {
      params.delete("faq")
    }
  }, [faqNumber, faqParam, params])

  return (
    <article className="copy">
      {faqs.map(({ faq, faq_title }, idx) => {
        return (
          <div
            className={`faq ${idx === +faqNumber ? "faq-active" : ""}`}
            id={`faq-${idx}`}
            onClick={() => {
              if (idx === faqNumber) params.delete("faq")
              if (idx !== faqNumber) updateParams("faq", idx)
              setFaqNumber(idx === faqNumber ? -1 : idx)
            }}
            onKeyUp={(ev) =>
              handleKeyUp(ev, () => {
                if (idx === faqNumber) params.delete("faq")
                if (idx !== faqNumber) updateParams("faq", idx)
                setFaqNumber(idx === faqNumber ? -1 : idx)
              })
            }
            role="button"
            tabIndex="0"
            aria-label={`See ${faq_title[0].text}`}
          >
            <h4>{faq_title[0].text}</h4>
            <div className="faq-content">
              {faq.map((paragraph) => {
                return paragraph.type === "paragraph" ? (
                  <p>{paragraph.text}</p>
                ) : (
                  <div />
                )
              })}
            </div>
          </div>
        )
      })}
    </article>
  )
}

export default FAQs
